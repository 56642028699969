import { Box, Grid, Stack, Typography, useMediaQuery, useTheme } from '@mui/material'
import React from 'react'
import { useTranslation } from 'next-i18next'
import LazyImage from '@/components/common/LazyImage'

export type ITeamBlockProps = {}

export const TeamBlock: React.FC<ITeamBlockProps> = ({}) => {
  const { t } = useTranslation('home')
  const theme = useTheme()
  const isMD = useMediaQuery(theme.breakpoints.up('md'))
  const info = [
    { avatar: '../imgs/home/Alv.png', name: 'Marcus', position: 'FOUNDER' },
    { avatar: '../imgs/home/Audi.png', name: 'ALV', position: 'CEO' },
    { avatar: '../imgs/home/Eugene.png', name: 'Audi', position: 'COO' },
    { avatar: '../imgs/home/Marcus.png', name: 'Eugene', position: 'CMO' },
    { avatar: '../imgs/home/Mina.png', name: 'MINA', position: 'CFO' },
  ]
  return (
    <Box
      mt={20}
      sx={{
        background: 'url(../imgs/teamBlockBg.jpg) no-repeat',
      }}
      p={isMD ? '250px 0px 48px 0px' : '30px 0 0 0 '}
    >
      <Stack
        direction="column"
        spacing={24}
        textAlign="center"
        maxWidth={840}
        justifyContent={'center'}
        margin={'0 auto'}
      >
        <Typography
          variant="emilysCandy"
          fontWeight={400}
          fontSize={isMD ? 64 : 30}
          color={'var(--ps-primary)'}
          suppressHydrationWarning
        >
          {t('time.title')}
        </Typography>
        <Typography suppressHydrationWarning fontSize={isMD ? 20 : 14}>
          {t('time.description')}
        </Typography>
      </Stack>
      <Grid container direction="row" justifyContent="center" alignItems="center" spacing={20} mt={isMD ? 58 : 20}>
        {info.map((_, index) => (
          <Grid item md={2} key={index}>
            <Box
              sx={{ borderRadius: '16px', background: 'rgba(1, 1, 1, 0.30)' }}
              p={isMD ? 20 : 10}
              mt={!isMD && 10}
              display={'flex'}
              justifyContent={'center'}
              alignItems={'center'}
            >
              <Stack direction="column" spacing={isMD ? 20 : 10}>
                <LazyImage src={_.avatar} width={220} height={220} alt="" />
                <Stack direction="column" textAlign={'center'} spacing={12}>
                  <Typography fontSize={16} color={'var(--ps-white)'}>
                    {_.name}
                  </Typography>
                  <Typography fontSize={16} color={'var(--ps-white)'} fontWeight={400} sx={{ opacity: 0.4 }}>
                    {_.position}
                  </Typography>
                </Stack>
              </Stack>
            </Box>
          </Grid>
        ))}
      </Grid>
    </Box>
  )
}
