import React from 'react'
import { Box, Container, useMediaQuery, useTheme } from '@mui/material'
import { serverSideTranslations } from 'next-i18next/serverSideTranslations'
import { BlindBlock } from '@/components/home/BlindBlock'
import { StakingBlock } from '@/components/home/StakingBlock'
import { TeamBlock } from '@/components/home/TeamBlock'
import PageLayout from '@/components/common/PageLayout'
import { VisionsBlock } from '@/components/home/VisionsBlock'

export default function Index() {
  const theme = useTheme()
  const isMD = useMediaQuery(theme.breakpoints.up('md'))

  return (
    <PageLayout disableGutters={!isMD}>
      <Box sx={{ pt: !isMD && 20, px: !isMD && 10 }}>
        <VisionsBlock />
        <Box
          pt={64}
          position={'relative'}
          sx={{
            background: 'url(../imgs/blockTwoBg.jpg) no-repeat 0px 0px',
            backgroundSize: '100% 100%',
            overflow: 'hidden',
          }}
        >
          {isMD && (
            <Box
              position={'absolute'}
              width={775}
              height={775}
              top={0}
              right={-100}
              sx={{
                background: 'url(../imgs/blockTwoRight.png) no-repeat',
              }}
            />
          )}
          {isMD && (
            <Box
              position={'absolute'}
              width={732}
              height={732}
              top={549}
              left={-100}
              sx={{
                background: 'url(../imgs/blockTwoLeft.png) no-repeat',
              }}
            />
          )}

          <Container maxWidth="lg">
            <BlindBlock />
            <StakingBlock />
          </Container>
        </Box>
        <TeamBlock />
      </Box>
    </PageLayout>
  )
}

export async function getStaticProps({ locale }) {
  return {
    props: {
      ...(await serverSideTranslations(locale, ['common', 'home'])),
    },
  }
}
