import { Box, Container, Stack, Typography, useMediaQuery, useTheme } from '@mui/material'
import React from 'react'
import { useTranslation } from 'next-i18next'
export type IVisionsBlockProps = {}

export const VisionsBlock: React.FC<IVisionsBlockProps> = ({}) => {
  const { t } = useTranslation('home')
  const theme = useTheme()
  const isMD = useMediaQuery(theme.breakpoints.up('md'))

  return (
    <Box
      minHeight={isMD && 846}
      sx={{
        flex: 1,
        background: 'url(../imgs/blockOne.jpg) center 50%',
        transition: 'background-size 6s',
        backgroundSize: isMD ? '110%' : 'cover',
        boxShadow: isMD
          ? 'inset 0 -200px 200px 20px #000,inset 0 200px 200px 20px #000'
          : 'inset 0px 0px 200px 60px #000',
        '&:hover': {
          backgroundSize: isMD && '100%',
        },
      }}
    >
      <Container maxWidth="lg">
        <Box pt={58} width={'100%'} zIndex={2}>
          <Stack direction="row" justifyContent="space-between">
            <Stack direction="column" mt={6} spacing={24} maxWidth={788}>
              <Typography
                variant="emilysCandy"
                fontWeight={400}
                fontSize={isMD ? 64 : 30}
                color={'var(--ps-primary)'}
                suppressHydrationWarning
                textAlign={!isMD ? 'center' : 'left'}
              >
                {t('head.title')}
              </Typography>
              <Typography
                suppressHydrationWarning
                fontSize={isMD ? 20 : 14}
                fontWeight={400}
                color={'var(--ps-white)'}
                sx={{ opacity: 0.7 }}
                lineHeight={isMD ? '200%' : '150%'}
              >
                {t('head.description')}
              </Typography>
            </Stack>
          </Stack>
        </Box>
      </Container>
    </Box>
  )
}
