import React from 'react'
import { Swiper, SwiperSlide } from 'swiper/react'
import { EffectCoverflow, Pagination } from 'swiper/modules'
import 'swiper/css'
import 'swiper/css/effect-coverflow'
import 'swiper/css/pagination'
import { Box } from '@mui/material'
import LazyImage from '@/components/common/LazyImage'
export type ICoverFlowSwiperProps = {}

const CoverFlowSwiper: React.FC<ICoverFlowSwiperProps> = ({}) => {
  const nftImges = ['one', 'two', 'three', 'four', 'five', 'six', 'seven']
  return (
    <Box
      sx={{
        '.swiper': {
          '.swiper-slide-shadow-left': {
            opacity: 0.5,
            backgroundImage: 'linear-gradient(to left, rgba(0, 0, 0, 1), rgba(0, 0, 0, 0))',
          },
          '.swiper-slide-shadow-right': {
            opacity: 0.5,
            backgroundImage: 'linear-gradient(to left, rgba(0, 0, 0, 1), rgba(0, 0, 0, 0))',
          },
        },
      }}
    >
      <Swiper
        modules={[EffectCoverflow, Pagination]}
        effect="coverflow"
        grabCursor={true}
        centeredSlides={true}
        slidesPerView={5}
        spaceBetween={0}
        initialSlide={3}
        coverflowEffect={{
          modifier: 2,
          rotate: 10,
          stretch: -30,
          depth: 200,
          slideShadows: true,
        }}
        pagination={false}
        className="mySwiper"
      >
        {nftImges.map((item, index) => {
          return (
            <SwiperSlide key={index}>
              <Box>
                <LazyImage src={`/imgs/swiper/${item}.jpg`} alt="" />
              </Box>
            </SwiperSlide>
          )
        })}
      </Swiper>
    </Box>
  )
}

export default CoverFlowSwiper
