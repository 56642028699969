import { Box, Stack, Typography, useMediaQuery, useTheme } from '@mui/material'
import React from 'react'
import { useTranslation } from 'next-i18next'
import { useRouter } from 'next/router'
import { useAccount } from 'wagmi'
import { useWeb3Modal } from '@web3modal/react'
import CoverFlowSwiper from '../CoverFlowSwiper'
import { ReactComponent as TimeSvg } from '@/assets/imgs/home/time.svg'
import { ReactComponent as BuySvg } from '@/assets/imgs/home/buy.svg'
import { CustomButton } from '@/components/common/CustomButton'

export type IBlindBlockProps = {}

export const BlindBlock: React.FC<IBlindBlockProps> = ({}) => {
  const { t } = useTranslation('home')
  const theme = useTheme()
  const isMD = useMediaQuery(theme.breakpoints.up('md'))
  const router = useRouter()
  const { address } = useAccount()
  const { open: web3Open } = useWeb3Modal()
  return (
    <Box>
      <Stack spacing={24} direction="column" alignItems={'center'} textAlign={'center'}>
        <Typography
          variant="emilysCandy"
          fontWeight={400}
          fontSize={isMD ? 64 : 30}
          color={'var(--ps-primary)'}
          suppressHydrationWarning
        >
          {t('blind.title')}
        </Typography>
        <Typography suppressHydrationWarning fontSize={isMD ? 20 : 14}>
          {t('blind.description')}
        </Typography>
      </Stack>
      <Stack direction="column" spacing={isMD ? 80 : 40} py={isMD ? 60 : 30}>
        <Stack
          direction={isMD ? 'row' : 'column'}
          justifyContent={isMD ? 'space-between' : 'left'}
          alignItems={isMD ? 'end' : 'flex-start'}
          spacing={!isMD && 20}
        >
          <Stack direction="column" spacing={20}>
            <Typography
              variant="emilysCandy"
              fontWeight={400}
              fontSize={isMD ? 36 : 16}
              color={'var(--ps-white)'}
              suppressHydrationWarning
            >
              {t('blind.stage1.title')}
            </Typography>
            <Typography suppressHydrationWarning fontSize={isMD ? 20 : 14}>
              {t('blind.stage1.description')}
            </Typography>
            <Stack direction="row" spacing={8} alignItems="center">
              <TimeSvg />
              <Typography suppressHydrationWarning fontWeight={600} fontSize={20} color={'var(--ps-primary)'}>
                {t('blind.openTime')} 2023.09.08
              </Typography>
            </Stack>
          </Stack>
          <CustomButton
            sx={{
              ':hover': { opacity: 0.5 },
            }}
            onClick={() => {
              if (address) {
                router.push('blind/stage/[id]', `blind/stage/1`)
              } else {
                web3Open()
              }
            }}
          >
            <BuySvg />
          </CustomButton>
        </Stack>
        <CoverFlowSwiper />
      </Stack>
      <Stack
        direction={isMD ? 'row' : 'column'}
        display="flex"
        justifyContent={'space-between'}
        mt={isMD ? 138 : 30}
        alignItems={!isMD && 'center'}
        spacing={!isMD && 20}
      >
        <Stack direction="column" spacing={20}>
          <Typography
            variant="emilysCandy"
            fontWeight={400}
            fontSize={isMD ? 36 : 16}
            color={'var(--ps-white)'}
            suppressHydrationWarning
          >
            {t('blind.stage2.title')}
          </Typography>
          <Typography suppressHydrationWarning fontSize={isMD ? 20 : 14}>
            {t('blind.stage2.description')}
          </Typography>
          <Stack direction="row" spacing={8} alignItems="center">
            <TimeSvg />
            <Typography suppressHydrationWarning fontWeight={600} fontSize={20} color={'var(--ps-primary)'}>
              {t('blind.openTime')} 2023.11.25
            </Typography>
          </Stack>
          <div>
            <CustomButton
              onClick={() => {
                if (address) {
                  router.push('blind/stage/[id]', `blind/stage/2`)
                } else {
                  web3Open()
                }
              }}
            >
              <BuySvg />
            </CustomButton>
          </div>
        </Stack>
        <video
          id="videos"
          width={isMD ? 530 : 300}
          height={isMD ? 530 : 300}
          src="/imgs/blind/newEge.mp4"
          playsInline
          style={{ borderRadius: '40px' }}
          muted={true}
          autoPlay={true}
          loop
        ></video>
      </Stack>
    </Box>
  )
}
