import { Box, Stack, Typography, useMediaQuery, useTheme } from '@mui/material'
import React from 'react'
import { useTranslation } from 'next-i18next'

export type IStakingBlockProps = {}

export const StakingBlock: React.FC<IStakingBlockProps> = ({}) => {
  const { t } = useTranslation('home')
  const theme = useTheme()
  const isMD = useMediaQuery(theme.breakpoints.up('md'))
  return (
    <Box pt={isMD ? 160 : 80} pb={isMD ? 16 : 8}>
      <Stack spacing={isMD ? 24 : 12} direction="column" alignItems={'center'} textAlign={'center'}>
        <Typography
          variant="emilysCandy"
          fontWeight={400}
          fontSize={isMD ? 64 : 30}
          color={'var(--ps-primary)'}
          suppressHydrationWarning
        >
          {t('stake.title')}
        </Typography>
        <Typography suppressHydrationWarning fontSize={isMD ? 20 : 14}>
          {t('stake.description')}
        </Typography>
      </Stack>
      <Box mt={isMD ? 62 : 30} textAlign="center">
        {/* <StackSwiper /> */}
        {/* <video
          id="videos"
          width={isMD ? 1000 : 366}
          height={isMD ? 650 : 240}
          playsInline
          muted={true}
          autoPlay={true}
          loop
        >
          <source src="/imgs/shop/honey.mp4" type="video/mp4" />
          <source src="/imgs/shop/honey.webm" type="video/webm" />
        </video> */}
        <video
          id="videos"
          width={isMD ? 540 : 300}
          height={isMD ? 540 : 300}
          src="/imgs/shop/1.mp4"
          playsInline
          muted={true}
          autoPlay={true}
          loop
        ></video>
      </Box>
    </Box>
  )
}
