import { ButtonBase, ButtonBaseProps } from '@mui/material'
import React from 'react'

type CustomButtonProps = {
  children: React.ReactNode
} & ButtonBaseProps

export const CustomButton = ({ children, sx, ...rest }: CustomButtonProps) => {
  return (
    <ButtonBase sx={{ boxShadow: '0 0 24px 0 rgba(247,176,24,0.7)', ':hover': { opacity: 0.5 }, ...sx }} {...rest}>
      {children}
    </ButtonBase>
  )
}
